import React from 'react';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';
import { useServerData } from '../../state/serverDataContext';

const TeaserES = Loadable({
  loader: () => import(/* webpackChunkName: 'TeaserES' */ './locales/TeaserES'),
  loading: () => <></>,
});
const TeaserIT = Loadable({
  loader: () => import(/* webpackChunkName: 'TeaserIT' */ './locales/TeaserIT'),
  loading: () => <></>,
});
const TeaserUK = Loadable({
  loader: () => import(/* webpackChunkName: 'TeaserUK' */ './locales/TeaserUK'),
  loading: () => <></>,
});
const TeaserMobileOnly = Loadable({
  loader: () => import(/* webpackChunkName: 'TeaserMobileOnly' */ './locales/TeaserMobileOnly'),
  loading: () => <></>,
});
const TeaserDefault = Loadable({
  loader: () => import(/* webpackChunkName: 'TeaserDefault' */ './locales/TeaserDefault'),
  loading: () => <></>,
});

export const TeaserPropTypes = {
  segment: PropTypes.shape({
    componentName: PropTypes.string,
    text: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.string,
        headerSecondLine: PropTypes.string,
        paragraph: PropTypes.string,
      })
    ),
    imgUrl: PropTypes.string,
    imgMobileUrl: PropTypes.string,
    imgWebpUrl: PropTypes.string,
    imgPositionY: PropTypes.string,
    btn: PropTypes.shape({}),
    appStoreBtn: PropTypes.shape({}),
  }).isRequired,
  index: PropTypes.number.isRequired,
  textBeforeImage: PropTypes.bool.isRequired,
};

const Teaser = ({ index, segment, textBeforeImage }) => {
  const { domain, locale, isMobile, isAndroid, isIOS } = useServerData(data => ({
    domain: data.domain,
    locale: data.locale,
    isMobile: data.isMobile || false,
    isAndroid: data.isAndroid || false,
    isIOS: data.isIOS || false,
  }));

  const isAtDomain = domain && domain === 'at';

  if (isAtDomain) return null;

  const LocaleTeaser =
    {
      'es-es': TeaserES,
      'it-it': TeaserIT,
      'en-mt': TeaserMobileOnly,
      'en-nz': TeaserMobileOnly,
      'en-is': TeaserMobileOnly,
      'en-ir': TeaserUK,
      'en-si': TeaserMobileOnly,
      'en-lu': TeaserMobileOnly,
      'en-mu': TeaserMobileOnly,
      'en-uk': TeaserUK,
      'de-at': TeaserES,
    }[locale] || TeaserDefault;

  return (
    <LocaleTeaser
      segment={segment}
      index={index}
      textBeforeImage={textBeforeImage}
      isMobile={isMobile}
      isAndroid={isAndroid}
      isIOS={isIOS}
    />
  );
};

Teaser.propTypes = TeaserPropTypes;

export default Teaser;
