import React from 'react';
import Teaser from '../Teaser';
import { useServerData } from '../../state/serverDataContext';

const TeaserContainer = () => {
  const { teaserData, locale, isMobile } = useServerData(serverData => ({
    teaserData: serverData.pageData?.data || [],
    locale: serverData.locale,
    isMobile: serverData.isMobile,
  }));

  if (!teaserData?.length) return null;

  return (
    <article data-testid="teaser_container">
      {teaserData.map((segment, index) => (
        <Teaser
          key={segment.componentName}
          segment={segment}
          locale={locale}
          isMobile={isMobile}
          index={index}
          textBeforeImage={!!(index % 2 === 0)}
        />
      ))}
    </article>
  );
};

export default TeaserContainer;
